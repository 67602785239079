.nemo-loading {
  position: fixed;
  top: 20px;
  right: 45%;
  color: #856404;
  background-color: rgba(255, 243, 205, .95);
  border-color: #ffeeba;
  z-index: 1100;
}

.nemo-sidebar {
  overflow-y: auto;
}

.nemo-sidebar h6 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.plot-disabled {
  opacity: 0.5;
}

@media (min-width: 992px) {
  .nemo-sidebar {
    position: sticky;
    top: 3.5rem;
    z-index: 1002;
    height: calc(100vh - 3.5rem);
  }
}

@media (min-width: 1200px) {
  .nemo-sidebar {
    -ms-flex: 0 0 25rem;
    flex: 0 0 25rem;
    max-width: 25rem;
  }
}
