.auro-data-table {
  max-height: 40rem;
  position: relative;
}

.auro-data-table table {
  border-collapse: separate;
  border-spacing: 0;
}

.auro-data-table th {
  top: 0;
  left: 0;
  z-index: 2;
  line-height: 2rem;
  position: sticky;
  background-color: #fff;
}

.auro-data-table th .btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.auro-data-table tbody {
  transition: opacity 0.15s ease-in-out;
}

.event-tags-list {
  max-height: 4.6rem;
  overflow: auto;
}

.event-tag {
  margin: 0.1rem;
  border-radius: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  height: 1.25rem;
  padding: 0;
  font-size: 0.75rem;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.event-tag .event-badge {
  display: flex;
  position: relative;
  align-items: center;
  height: 0.9rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  flex-shrink: 0;
  font-size: 0.7rem;
  justify-content: center;
  border-radius: 0.55rem;
  font-weight: bold;
}

.event-tag .event-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-overflow: ellipsis;
}

.event-tag .badge-left {
  margin-left: 0.25rem;
  margin-right: -0.5rem;
  width: 1.1rem;
  color: rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
  opacity: 90%;
}

.event-tag .badge-right {
  margin-left: -0.5rem;
  margin-right: 0.25rem;
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.9);
}

.id-tag .event-tag {
  max-width: 5rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

/* .id-tag:hover .event-tag {
  max-width: initial;
  position: absolute;
  z-index: 1000;
  right: 0.85rem;
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  box-shadow: 0 0.05rem 0.05rem 0.05rem rgba(0,0,0,0.25);
} */

.col-sortable {
  color: #212529;
  font-weight: bold;
  padding-left: 0;
  padding-right: 0;
}

.col-sortable:hover {
  color: #212529;
}

.col-sortable:focus {
box-shadow: initial;
}

.col-sortable:disabled {
  opacity: 1;
}

.col-sort-icon {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 1rem;
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.col-sort-icon:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
